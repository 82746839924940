footer.content-info {
	position: fixed;
	bottom: 32px;
	left: 32px;
	width: calc(100% - 64px);
	border-bottom: solid 1px $white;
	z-index: 1000;

	.footer-contact {
		opacity: 1;
	    position: absolute;
	    color: $white;
	    font-family: $font-family-accent;
	    font-size: rem-calc(14px);
	    font-weight: 300;
	    text-transform: uppercase;
	    letter-spacing: rem-calc(1px);
	    margin: 0;
	    padding-top: 8px;
	    transition: opacity .5s;

	    &.show {
	    	opacity: 1 !important;
	    }

	    a {
	    	color: $white;
	    }

	    @include media-breakpoint-down(sm) {
	    	.state, .zip, .seperator, .phone {
	    		display: none;
		    }
	    }
		    
	}

	.social-links {
		position: absolute;
		right: 12px;
		bottom: 6px;

		@include media-breakpoint-down(sm) {
			right: -28px;
			bottom: -10px;
		}

		.social-link {
			display: block;
			color: $white;
			font-size: 30px;
			line-height: 1;
			margin-bottom: rem-calc(5px);

			&:hover {
				@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
				display: block;
			}

			@include media-breakpoint-down(sm) {
				font-size: 28px;
			}
		}
	}
}

.home footer.content-info .footer-contact {
	// opacity: 0;
}