.exhibitions, .single-exhibitions {

	#exhibition-intro {
		margin-top: rem-calc(32px);
		
		.exhibitions {
			display: flex;
			justify-content: center;
		}

		.exhibit-hero {
			display: flex;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			width: calc(100% - 64px);
			height: calc(100vh - 64px);

			.content {
				display: block;
				align-self: flex-end;
				padding: rem-calc(60px);

				@include media-breakpoint-down(sm) {
					padding: rem-calc(15px);
				}
			}

			.title {
				font-family: $font-family-sans-serif-display;
				font-size: rem-calc(60px);

				@include media-breakpoint-down(sm) {
					font-size: rem-calc(28px);
				}
			}

			.title, .subtitle, .show-dates {
				width: auto;
				display: inline-block;
				background: $black;
				padding: 8px;
				text-transform: uppercase;

				@include media-breakpoint-down(sm) {
					background-color: rgba(0, 0, 0, 0.8);
				}
			}

			.subtitle, .show-dates {
				@include media-breakpoint-down(sm) {
					font-size: rem-calc(18px);
				}
			}
		}
	}

	#exhibition-content {
		.content {
			@include media-breakpoint-down(sm) {
				// width: 100%;
				// left: 90px;
			}
		}
	}

	.entry-title {
		color: $black;
		background: $white;
		margin-bottom: rem-calc(60px);

		span {
			display: block;
			color: $white;
			background: $black;
			font-weight: 100;
		}

		small {
		    font-size: 60%;
		    font-weight: 100;
		    text-transform: uppercase;
		    letter-spacing: 1px;
		    opacity: 0.6;
		}
	}

	.exhibit-info {
		border: solid 1px $white;
		padding-bottom: rem-calc(60px);
		margin-bottom: rem-calc(60px);
	}

	.entry-content {
		padding-top: rem-calc(60px);
		font-size: rem-calc(18px);

		// p:first-of-type {
		// 	font-size: 2vw;
		// 	font-weight: 100;
		// 	line-height: 1.2;
		// }

		.external-links {
			font-size: rem-calc(40px);

			.external-link {
				&:hover {
					@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
				}
			}
		}
	}

	.artist-gallery {
		padding: rem-calc(100px) 0;
	}

	.artist-gallery-title {
		font-size: 90px;
		hyphens: auto;
		line-height: 1;
		padding: 0 rem-calc(5px);

		a {
			&:hover {
				@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
			}
		}
	}

	.exhibit-artists {
		padding-bottom: rem-calc(80px);

		.section-header {
			display: inline-block;
			padding: 8px;
			background: $white;
			color: $black;
			text-transform: uppercase;
			letter-spacing: 1px;

			@include media-breakpoint-down(sm) {
				margin-left: 15px;
			}
		}
	}

	.tilter {
		margin-bottom: rem-calc(30px);
	}

	.gallery-image {
		position: relative;
		padding: rem-calc(30px);
		background: $white;

		.artwork-title {
			position: absolute;
			bottom: 0;
			left: 15px;
			transform: rotate(-90deg);
			transform-origin: left;
			font-size: rem-calc(11px);
			font-weight: 100;
			color: $black;
			text-transform: uppercase;
			overflow: hidden;
		    text-overflow: ellipsis;
		    width: 320px;
		    padding-left: rem-calc(25px);
			
			&:before {
				content: '';
				height: 1px;
				background: $black;
				width: 20px;
				position: absolute;
				left: 0;
				top: 6px;
			}
		}

		img {
		}
	}
}

.post-type-archive-exhibitions {

	.page-header {
		display: none;

		h1 {
			position: relative;
			text-align: center;
			margin-top: rem-calc(20px);
			mix-blend-mode: multiply;
			
			&:before {
				content: 'Exhibitions';
				position: relative;
				top: -2px;
				left: calc(50% - 168px);
				mix-blend-mode: multiply;
			}
			
			&:after {
				content: 'Exhibitions';
				position: relative;
				top: 2px;
				left: calc(50% - 165px);
				mix-blend-mode: multiply;
			}
		}
	}

	#exhibitions-intro {
		padding: rem-calc(32px);
	}

	.page-intro {
		margin-bottom: rem-calc(60px);
	}

	section {
		padding-bottom: rem-calc(80px);
	}

	.section-header {
		display: inline-block;
		margin: 0 0 30px -56px;
		padding: 8px;
		background: $white;
		color: $black;
		text-transform: uppercase;
		letter-spacing: 1px;

		@include media-breakpoint-down(sm) {
			position: relative;
			z-index: 1000;
			margin-left: -30px;
			width: calc(100% + 45px);
		}
	}

	.exhibit-hero-slider {
		width: 100%;

		&:before {
			content:'';
			position: absolute;
			width: 100%;
			height: 300px;
			background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, #000000 100%);
			z-index: 100;
		}

		a {
			&:hover {
				background: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
			    -webkit-background-clip: text;
			    -webkit-text-fill-color: transparent;
			}
		}

		.exhibit-hero-swiper-container {
			width: 100%;
			height: 100%;

			.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
				background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23ffffff'/%3E%3C/svg%3E");

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
				background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23ffffff'/%3E%3C/svg%3E");

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			.swiper-slide {
				transition: all 0.33ms;

				&:before {
			    	content: '';
			    	display: flex;
			    	position: absolute;
			    	height: calc(100vh - 64px);
			    	width: 100%;
			    	z-index: 100;
			    	opacity: 0.6;
			    }

			    &.changing {
			    	&:before {
			    		opacity: 0;
			    	}
			    }

				&.blue_purple:before { @include blue_purple-bg; }
			    &.yellow_orange:before { @include yellow_orange-bg(); }
			    &.lpurple_dpurple:before { @include lpurple_dpurple-bg(); }
			    &.black_gray:before { @include black_gray-bg(); }
			    &.teal_turquoise:before { @include teal_turquoise-bg(); }
			    &.dgreen_lgreen:before { @include dgreen_lgreen-bg(); }
			    &.dgray_lgray:before { @include dgray_lgray-bg(); }
			    &.dblue_lblue:before { @include dblue_lblue-bg(); }
			}

			.row {
				position: relative;
				// justify-content: space-evenly;
				width: 100%;
				height: calc(100vh - 64px);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				transition: all 0.5s;

				&.changing {
					background-position: 50% 350%;

					.content {
						display: none;
					}
				}

				.content {
					position: relative;
					display: block;
					align-self: flex-end;
					padding: rem-calc(60px);
					z-index: 1000;

					@include media-breakpoint-down(sm) {
						padding: rem-calc(15px);
					}
				}
			}

			.title {
				font-family: $font-family-sans-serif-display;
				font-size: rem-calc(60px);

				@include media-breakpoint-down(sm) {
					font-size: rem-calc(28px);
				}
			}

			.title, .subtitle, .show-dates {
				width: auto;
				display: inline-block;
				background: $black;
				padding: 8px;
				text-transform: uppercase;

				@include media-breakpoint-down(sm) {
					background-color: rgba(0, 0, 0, 0.8);
				}
			}

			.subtitle, .show-dates {
				@include media-breakpoint-down(sm) {
					font-size: rem-calc(18px);
				}
			}
		}
	}

	.exhibit-upcoming-slider {
		position: relative;
		
		.swiper-button-next, .swiper-button-prev {
			margin-top: -72px;
		}

		.swiper-button-next {
			right: -40px;
		}

		.swiper-button-prev {
			left: -40px;
		}

		.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23ffffff'/%3E%3C/svg%3E");
		}

		.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23ffffff'/%3E%3C/svg%3E");
		}

		.exhibit-upcoming-swiper-container {

			.swiper-wrapper.row {
				flex-wrap: nowrap;
			}
			.swiper-slide {
				width: auto;
				background-position: center center ;
				background-size: cover;
				background-repeat: no-repeat;
				display: block;
				height: 100%;

				@include media-breakpoint-down(sm) {
					width: 100%;
				}

				.title {
					font-size: rem-calc(32px);
					margin-bottom: 0;
				}

				.subtitle, .show-dates {
					font-size: rem-calc(20px);
					font-weight: 100;
				}
			}
		}
	}

	.exhibits-past {

		.panel {
			margin: rem-calc(20px 0);

			@include media-breakpoint-down(sm) {
				margin: rem-calc(30px 0);
			}

			&:nth-child(odd) .content:after {
				transform: rotate(-3deg);
			}
			&:nth-child(even) .content:after {
				transform: rotate(3deg);
			}

			&:hover {
				.content:after {
					transform: rotate(0deg);
				}
			}
		}

		.content {
			position: relative;
			display: block;
			padding: rem-calc(10px 2px);
			background: $white;
			color: $black;

			&:after {
				content: '';
				position: absolute;
				top: -2.5%;
				left: -2.5%;
				border: solid 1px $white;
				z-index: -1;
				width: 105%;
				height: 105%;
				transition: transform 0.25s linear;
			}

			&:hover {
				&:after {
					transform: rotate(0deg);
				}
			}

			a {
				color: $black;
			}
		}
		.event-featured-img {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			width: 100%;
			@include aspect-ratio(1, 1);
		}

		.event-title {
			@include responsive-font(1.25vw, 18px, 30px, 18px);
		}

		#load-more {
			position: relative;
			left: calc(50% - 60px);
			width: 120px;
			margin-top: 40px;
		}
	}
}

.tilter__image {
	&.img-holder {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}