.module {
    padding: rem-calc(60px 0);

    @include media-breakpoint-down(sm) {
        padding-top: 0;
        padding-bottom: rem-calc(60px);
    }
}

// .text-flexible {
//     padding-top: rem-calc(46px);
//     padding-bottom: rem-calc(84px);
//     color: $gray-800;

//     .container {
//         width: 100%;
//     }

//     @include media-breakpoint-down(sm) {
//         padding-bottom: rem-calc(60px);
//     }
//     h2 {
//         font-family: $font-family-sans-serif;
//         font-size: $display2-size;
//         font-weight: 300;
//         letter-spacing: rem-calc(-2px);
//         line-height: rem-calc(63px);
//         margin-bottom: rem-calc(30px);

        
//     }

//     &.auto-2-col-header-span h2 {
//         column-span: all;
//     }

//     p {
//         opacity: 0.8;
//         font-family: $font-family-sans-serif;
//         font-size: rem-calc(16px);
//         font-weight: 400;
//         line-height: rem-calc(30px);
//     }

//     &.auto-1-col {
//         .content {
//             text-align: center;

//             .content-column {
//                 text-align: left;
//             }
//         }
//     }

//     &.auto-2-col, &.auto-2-col-header-span {
//         .content {
//             column-count: 2;
//             column-gap: 30px;
//             text-align: left;

//             @include media-breakpoint-down(sm) {
//                 column-count: 1;
//             }
//         }
//     }

//     &.man-2-col-header-span, &.man-2-col {
//         .row {
//             justify-content: center;
//         }
//     }

//     &.module-gradient {
//         padding-top: rem-calc(170px);
//         padding-bottom: rem-calc(170px);
//     }

//     &.blue_purple { @include blue_purple-bg; color: $white;}
//     &.yellow_orange { @include yellow_orange-bg(); color: $white;}
//     &.lpurple_dpurple { @include lpurple_dpurple-bg(); color: $white;}
//     &.black_gray { @include black_gray-bg(); color: $white;}
//     &.teal_turquoise { @include teal_turquoise-bg(); color: $white;}
//     &.dgreen_lgreen { @include dgreen_lgreen-bg(); color: $white;}
//     &.dgray_lgray { @include dgray_lgray-bg(); color: $white;}
//     &.dblue_lblue { @include dblue_lblue-bg(); color: $white;} 
//     &.purple_teal {@include teal_purple-bg(); color: $white;}   
// }

/***** Image Row Module *****/
.image-row {
    padding-bottom: rem-calc(45px);

    .image-wrapper {
        justify-content: center;
        align-items: stretch;
        align-content: stretch;
        box-shadow: 0 26px 16px 0 rgba(0,0,0,0.2);

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            flex-wrap: wrap;
        }
        
        @include media-breakpoint-up(md) {
            flex-direction: row;
            flex-wrap: nowrap;
        }
        
        .image-holder {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            flex-grow: 1;
            flex-shrink: 1;
            
            @include media-breakpoint-down(sm) {
                @include aspect-ratio(1, 1);
                width: 100%;
            }
            
            @include media-breakpoint-up(md) {
                @include aspect-ratio(1, 1.5);
            }
        }
    }
}

/***** Image Gallery Module *****/
.image-gallery {

    .gallery-swiper-container {
        padding: rem-calc(60px 0);

        .swiper-wrapper {

            .swiper-slide {
                 @include aspect-ratio(1.9, 1);
                 background-position: center center;
                 background-repeat: no-repeat;
                 background-size: contain;
            }
        }

        .swiper-pagination {
            .swiper-pagination-bullet {
                @include teal_turquoise-bg();
                @extend .animated;
                opacity: 0.2;
            }
            .swiper-pagination-bullet-active {
                opacity: 1;
                transform: scale(1.5);
            }
        }
    }
}

/***** Image and Text Feature Module *****/
.image-text-feature {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    padding: rem-calc(75px 0);
    margin: rem-calc(100px 0);

    &.left {
        background-position: left center;

        .row {
            justify-content: flex-end;
        }
    }

    &.right {
        background-position: right center;
    }

    .feature-text {
        padding: rem-calc(80px 135px);
        color: $white;

        h3 {
            color: $white;
            font-size: rem-calc(32px);
            font-weight: 300;
            margin-top: 0;
        }

        h5 {
            font-size: rem-calc(14px);
            font-weight: 500;
        }

        p {
            color: $white;
        }

        &.blue_purple { @include blue_purple-bg(); }
        &.yellow_orange { @include yellow_orange-bg(); }
        &.lpurple_dpurple { @include lpurple_dpurple-bg(); }
        &.black_gray { @include black_gray-bg(); }
        &.teal_turquoise { @include teal_turquoise-bg(); }
        &.dgreen_lgreen { @include dgreen_lgreen-bg(); }
        &.dgray_lgray { @include dgray_lgray-bg(); }
        &.dblue_lblue { @include dblue_lblue-bg(); }
    }

    @include media-breakpoint-down(sm) {
        padding: rem-calc(120px 20px 0);
        margin: rem-calc(60px 0);
        &.left, &.right {
            background-position: center top;
            .row {
                justify-content: flex-center;
            }
        }

        .feature-text {
            padding: rem-calc(50px 20px);

            h3 {
                margin: rem-calc(30px 0);
            }

            ul {
                padding-left: rem-calc(20px);
            }
        }
    }
}

.text-block {
    @include media-breakpoint-down(sm) {
        padding: rem-calc(20px);
        padding-top: rem-calc(30px);
        text-align: center;
    }
    @include media-breakpoint-up(md) {
        padding: rem-calc(0 135px 135px 135px);
        text-align: left;
        padding-top: 8vh;
    }
    .gradientText {
        font-family: $font-family-serif;
        @include teal_purple-text();
        @include media-breakpoint-down(sm) {
            font-size: 8vw;
        }
        @include media-breakpoint-up(md) {
            font-size: 5vw;
        } 
    }
    h1 {
        font-family: $font-family-serif;
        @include teal_purple-text();
        @include media-breakpoint-down(sm) {
            font-size: 8vw;
        }
        @include media-breakpoint-up(md) {
            font-size: rem-calc(88px);
        }
    }
    p {
        font-size: rem-calc(18px);
        color: #097299;
        padding-top: 60px;
        padding-bottom: 60px;
        @include media-breakpoint-down(sm) {
            padding-top: 10px;
        }
    }
}

/***** Contact Module *****/
.contact-form {
    margin-bottom: rem-calc(60px);
}

/***** Contact Form 7 *****/
#wpcf7-f7-p121-o1 {

    .row {
        margin-bottom: 14px;
    }

    .wpcf7-form-control-wrap {
        width: 100%;
        background: $white;
    }

    input[type=text], input[type=email], input[type=tel], textarea, select {
        border: solid 3px $white;
        font-size: 18px;
        font-weight: 400;
        width: 100%;
        background: $white;
        color: $black;
        font-family: $font-family-sans-serif;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: $black;
        opacity: 1;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: $black;
        opacity: 1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
       font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: $black;
        opacity: 1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
       font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: $black;
        opacity: 1;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
       font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: $black;
        opacity: 1;
    }

    ::placeholder { /* Most modern browsers support this now. */
       font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: $black;
        opacity: 1;
    }

    &::placeholder {
        font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: $black;
        opacity: 1;
    }

    select {
        background: transparent url('../images/select-arrow.svg') no-repeat right 10px center;
        background-size: 4%;
        padding-right: 60px;
        -webkit-appearance: none;

        option:first-child {
            line-height: 20px;
            font-weight: 400;
            font-size: 18px;
        }
    }

    .name-num {
        .wpcf7-form-control-wrap {
            width: 49%;
        
            &:first-child {
                margin-right: 2%;
            }
        }
    }

    input[type=submit] {
        @extend .btn;
        @extend .btn-lg;
        @extend .btn-light;
        text-transform: uppercase;

        &:hover {
            background: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

/***** Store Hours and Google Map Module *****/
.map-hours-module {
    padding-bottom: rem-calc(60px);

    .hours-container {
        font-family: $font-family-sans-serif;
        font-weight: 300;
        font-size: rem-calc(20px);
        letter-spacing: 1px;
        // margin: rem-calc(0 40px 100px 0);

        ul.hours {
            list-style: none;
            color: $white;
            margin: 0;
            padding-left: 5rem;

            li {
                margin-bottom: rem-calc(10px);
                span.day {
                    display: inline-block;
                    min-width: rem-calc(100px);
                    margin-right: rem-calc(12px);
                    text-align: left;
                    text-transform: uppercase;
                    font-size: rem-calc(16px);
                    font-weight: 900;
                }

                &.weekday:nth-child(-n+3) {
                    margin-left: rem-calc(30px);
                }
            }
        }
    }

    .map-parent {
        height: 400px;
        
        #map {
            width: 100%;
            height: 100%;
            color: $black;

            a {
                color: $gray-900;
                text-decoration: underline;

                &:hover {
                    color: $black;
                }
            }
        }
    }

    #directionsPanel {
        background: $white;
        margin-bottom: rem-calc(60px);
    }
}
