.home {
	div.container[role=document] {
		// padding-top: 0;
		padding: rem-calc(32px);

		@include media-breakpoint-up(lg) {
			max-width: none;
		}
	}
}

.scene {
	min-height: 100vh;
	padding: rem-calc(100px 32px);
	margin-left: auto;
	margin-right: auto;
	width: 100%;

	@include media-breakpoint-only(lg) {
		max-width: 960px;
	}

	@include media-breakpoint-only(xl) {
		max-width: 1140px;
	}

	@include media-breakpoint-down(sm) {
		padding: rem-calc(100px 16px);
	}

	&.full-width {
		position: relative;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		box-shadow: inset 0 0 20px 0 #000000;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 65%, #000000 100%);
		}

		@include media-breakpoint-up(lg) {
			max-width: none;
			min-height: calc(100vh - 64px);
			margin-top: rem-calc(32px);
		}
	}
}

#scene0 {
	position: relative;
	display: flex;
	padding: 0;
	max-width: none;
	// justify-content: space-between;
}

.logo {
	position: absolute;
    top: 20px;
    left: calc(50% - 200px);
	display: block;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	width: 400px;
	height: auto;
	z-index: 1000;
	transition: all 0.3s;

	@include media-breakpoint-down(sm) {
		width: 280px;
		left: calc(50% - 140px);
	}

	img {
		width: 100%;
		height: auto;
	}

	&.hide {
		transform: scale(0);
		opacity: 0;
	}
}

.contact-intro {
	align-self: center;

	.home-contact {
		span {
			display: block;
			font-family: $font-family-sans-serif;
			font-weight: 300;
			font-size: rem-calc(24px);
			color: $white;
			line-height: rem-calc(84px);
		}
	}
}

.home-hero-slider {
	width: 100%;

	&:before {
		content:'';
		position: absolute;
		width: 100%;
		height: 300px;
		background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, #000000 100%);
		z-index: 100;
	}

	a {
		&:hover {
			background: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
		    -webkit-background-clip: text;
		    -webkit-text-fill-color: transparent;
		}
	}

	.home-hero-swiper-container {
		width: 100%;
		height: 100%;

		.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23ffffff'/%3E%3C/svg%3E");

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23ffffff'/%3E%3C/svg%3E");

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		.swiper-slide {
			transition: all 0.33ms;

			&:before {
		    	content: '';
		    	display: flex;
		    	position: absolute;
		    	height: calc(100vh - 64px);
		    	width: 100%;
		    	z-index: 100;
		    	opacity: 0.6;
		    }

		    &.changing {
		    	&:before {
		    		opacity: 0;
		    	}
		    }

			&.blue_purple:before { @include blue_purple-bg; }
		    &.yellow_orange:before { @include yellow_orange-bg(); }
		    &.lpurple_dpurple:before { @include lpurple_dpurple-bg(); }
		    &.black_gray:before { @include black_gray-bg(); }
		    &.teal_turquoise:before { @include teal_turquoise-bg(); }
		    &.dgreen_lgreen:before { @include dgreen_lgreen-bg(); }
		    &.dgray_lgray:before { @include dgray_lgray-bg(); }
		    &.dblue_lblue:before { @include dblue_lblue-bg(); }
		}

		.row {
			position: relative;
			// justify-content: space-evenly;
			width: 100%;
			height: calc(100vh - 64px);
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center center;
			transition: all 0.5s;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 33.3%;
				height: calc(100vh - 64px);
				background-image: inherit;
				background-repeat: no-repeat;
				background-size: 300%;
				background-position: center left;
				border-right-width: 2px;
				border-right-style: solid;
				border-image: linear-gradient(to top, black, rgba(0, 0, 0, 0)) 1 100%;
				transition: all 1s;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 33.3%;
				height: calc(100vh - 64px);
				background-image: inherit;
				background-repeat: no-repeat;
				background-size: 300%;
				background-position: center right;
				border-left-width: 2px;
				border-left-style: solid;
				border-image:
				linear-gradient(to top, black, rgba(0, 0, 0, 0)) 1 100%;
				transition: all 1s;
			}

			@include media-breakpoint-down(sm) {
				background-position: center center;
				background-size: cover;

				&:before {
					background-position:center left ;
				}

				&:after {
					background-position:center right ;
				}

				&:before, &:after {
					// background-size: 306%;
					display: none;
				}
			}

			&.changing {
				// cursor: pointer;
				background-position: 50% 350%;

				&:before {
					background-position: -150% 50%;
					// width: 10px;
					transition-delay: 0.5s;
				}

				&:after {
					background-position: 200% 50%;
					transition-delay: 0.5s;
				}
			}

			.content {
				position: relative;
				display: block;
				z-index: 1000;
			}
		}

		.title {
			position: relative;
			padding-top: 300px;
			font-family: $font-family-sans-serif-display;
			font-size: rem-calc(72px);
			z-index: 1000;

			@include media-breakpoint-down(sm) {
				font-size: rem-calc(32px);
			}
		}

		.text-bg {
			.title, .subtitle, .show-dates, .event-dates {
				width: auto;
				display: inline-block;
				background: $black;
				padding: 8px;
				
				@include media-breakpoint-down(sm) {
					background-color: rgba(0, 0, 0, 0.8);
				}
			}
			
		}

		.text-no-bg {
		}

		.option1, .video_option {

			.content {
				align-self: flex-end;
				padding: rem-calc(20px);
			}

			.title {
				text-align: left;
				font-size: rem-calc(60px);

				@include media-breakpoint-down(sm) {
					font-size: rem-calc(28px);
				}
			}

			.subtitle, .show-dates, .event-dates {
				@include media-breakpoint-down(sm) {
					font-size: rem-calc(18px);
				}
			}

			.cta-button {
				display: inline-block;
			}
		}

		.option2 {

			.content {
				text-align: center;
			}

			.title {
				font-size: rem-calc(60px);

				@include media-breakpoint-down(sm) {
					font-size: rem-calc(28px);
				}
			}

			.subtitle, .show-dates, .event-dates {
				@include media-breakpoint-down(sm) {
					font-size: rem-calc(18px);
				}
			}

			.cta-button {
				display: inline-block;
			}
		}

		.option3 {

			.content {
				align-self: flex-end;
				padding: rem-calc(20px);
			}

			.img-layer {
				padding: rem-calc(60px);

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			.title {
				text-align: left;
				font-size: rem-calc(60px);

				@include media-breakpoint-down(sm) {
					font-size: rem-calc(28px);
				}
			}

			.title, .subtitle, .show-dates {
				width: auto;
				display: inline-block;
				background: $black;
				padding: 8px;
				text-transform: uppercase;
			}

			.cta-button {
				display: inline-block;
			}

			.large-image {
				@include media-breakpoint-up(xs) {
					display: none;
				}

				@include media-breakpoint-up(md) {
					display: block;
				}
				width: 65%;
				max-width: 640px;
				@include aspect-ratio(1, 1);
				z-index: 1001;
				position: absolute;
				right: 60px;
				top: 32%;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				box-shadow: 0 26px 16px 0 rgba(0,0,0,0.20);
			}
			.small-image {
				@include media-breakpoint-up(xs) {
					display: none;
				}

				@include media-breakpoint-up(md) {
					display: block;
				}
				width: 35%;
				max-width: 270px;
				@include aspect-ratio(.75, 1);
				z-index: 1002;
				position: absolute;
				right: 60%;
				top: 38%;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				box-shadow: 0 26px 14px 0 rgba(0,0,0,0.20);
			}
		}

		.fullscreen-bg__video {
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    overflow: hidden;
		    z-index: 101;

		    iframe {
		    	width: 100vw;
				height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
				min-height: 100vh;
				min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
		    }
		}
	}
}

@keyframes flickerAnimation {
  0%   { opacity: 1; }
  50%  { opacity: 0.25; }
  100% { opacity: 1; }
}

.scroll-down {
	// align-self: flex-end;
	position: absolute;
	bottom: 70px;
	left: calc(50% - 45px);
	z-index: 980;
	animation: flickerAnimation 4s infinite;

	&:hover {
		cursor: pointer;
	}

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.featured-posts-module {
	width: 100%;
	position: relative;

	@include media-breakpoint-down(sm) {
		padding: 0;
	}

	.module-title {
		font-family: $font-family-sans-serif-display;
		font-weight: 300;
		text-transform: uppercase;
		letter-spacing: rem-calc(2px);
	}

	.cats {
		position: relative;
	    left: -20px;
	    z-index: 100;
		box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.25);
		a {
			display: inline-block;
			background: $white;
			padding: rem-calc(2px 12px);
			font-family: $font-family-sans-serif;
			font-size: rem-calc(24px);
			color: $black;
			letter-spacing: rem-calc(2px);
			text-transform: uppercase;
		}
		
	}

	.event-date {
		// display: inline-block;
		background: $white;
		padding: rem-calc(2px 12px);
		// font-family: $font-family-sans-serif;
		font-size: rem-calc(24px);
		color: $black;
		letter-spacing: rem-calc(2px);
		text-transform: uppercase;
	}

	.img-container {
		position: relative;
		display: block;
		@include aspect-ratio(1, 1);
		width: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		padding: rem-calc(15px);
		padding-left: 0;

		&:after {
			content: '';
			position: absolute;
			top: -5%;
			left: -5%;
			border: solid 1px $white;
			z-index: 10;
			width: 105%;
			height: 105%;
			transform: rotate(-3deg);
			transition: transform 0.25s linear;
		}

		&:hover {
			&:after {
				transform: rotate(0deg);
			}
		}
	}

	.post-title {
		font-family: $font-family-sans-serif-display;
		font-size: rem-calc(62px);
		font-weight: 700;
		color: $white;
		line-height: 1.2;

		&:hover {
			@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
			display: block !important;
		}

		@include media-breakpoint-down(sm) {
			font-size: rem-calc(32px);
		}
	}

	.more {
		font-family: $font-family-sans-serif;
		font-size: rem-calc(24px);
		font-weight: 300;
		color: $white;
		letter-spacing: rem-calc(2px);
		border-bottom: solid 3px double;

		&:hover {
			@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
		}
	}

	.swiper-container {
		width: 100%;
		height: 100%;
		padding: rem-calc(0 40px 40px);

		@include media-breakpoint-down(sm) {
			padding: rem-calc(0 20px 20px);
		}

		.swiper-pagination-bullet {
			background: $white;
		}

		.swiper-pagination-bullets {
			bottom: 0;
		}

		.swiper-pagination-bullet-active {
			background: linear-gradient(270deg, #330876, #30cfd0);
			background-size: 300% 300%;

			animation: AnimatedGradient 6s ease infinite;

			@-webkit-keyframes AnimatedGradient {
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			@-moz-keyframes AnimatedGradient {
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			@keyframes AnimatedGradient { 
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			transition: all .5s;
			transform: scale(1.5);
		}
    }
    .swiper-slide {
		font-size: 18px;
		color:#fff;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: rem-calc(20px 80px 20px 120px);
		// min-width: 78vw;
		// margin-right: rem-calc(20px);
		width: 100%;

		@include media-breakpoint-down(sm) {
			padding: 0;
			padding-bottom: 20px;
		}
    }
}

.featured-artists-module {
	.module-title {
		background: $white;
		padding: rem-calc(2px 12px);
		font-family: $font-family-sans-serif-display;
		font-size: rem-calc(24px);
		font-weight: 400;
		color: $black;
		letter-spacing: rem-calc(2px);
		text-transform: uppercase;
	}

	.content {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.artist-slider {
		// padding: rem-calc(0px 20px 20px);

		@include media-breakpoint-down(sm) {
			padding: 0;
			padding-bottom: 20px;
		}

		.swiper-pagination-bullet {
			background: $white;
		}

		.swiper-pagination-bullet-active {
			background: linear-gradient(270deg, #330876, #30cfd0);
			background-size: 300% 300%;

			animation: AnimatedGradient 6s ease infinite;

			@-webkit-keyframes AnimatedGradient {
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			@-moz-keyframes AnimatedGradient {
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			@keyframes AnimatedGradient { 
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			transition: all .5s;
			transform: scale(1.5);
		}

		.cta-button {
			margin-right: rem-calc(30px);
			text-transform: uppercase;

			&:hover {
				background: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
			    -webkit-background-clip: text;
			    -webkit-text-fill-color: transparent;
			}

			@include media-breakpoint-down(sm) {
				margin: 10px 0;
				margin-right: -15px;
			}
		}
	}

	.artist-more {
		width: 100%;
		text-align: center;
		margin-top: rem-calc(20px);

		.more {
			font-family: $font-family-sans-serif;
			font-size: rem-calc(24px);
			font-weight: 300;
			color: $white;
			letter-spacing: rem-calc(2px);
			border-bottom: solid 3px double;

			&:hover {
				@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
			}
		}
	}

	.swiper-pagination-bullets {
		bottom: 0;
	}
}

.featured-products-module {
	h2 {
		position: relative;
		margin-bottom: rem-calc(60px);
		font-family: $font-family-sans-serif-display;
		font-size: rem-calc(102px);
		@include responsive-font(8vw, 45px, 160px, 70px);
		color: $white;
		text-align: center;
		font-weight: 300;
		letter-spacing: 10px;

		strong {
			font-weight: 700;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -7%;
			width: 100%;
			height: 50%;
			border: 3px solid $white;
		}
	}

	.product-row {
		margin-bottom: rem-calc(60px);
		padding-left: rem-calc(30px);
	}

	.product-container {
		position: relative;

		&:nth-child(odd) .product:after {
			transform: rotate(-3deg);
		}
		&:nth-child(even) .product:after {
			transform: rotate(3deg);
		}

		.product {
			position: relative;
			display: block;
			@include aspect-ratio(1, 1);
			width: 90%;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;

			&:after {
				content: '';
				position: absolute;
				top: -2.5%;
				left: -2.5%;
				border: solid 1px $white;
				z-index: -1;
				width: 105%;
				height: 105%;
				transition: transform 0.25s linear;
			}

			&:hover {
				&:after {
					transform: rotate(0deg);
				}
			}
		}
	}

	.products-more {
		width: 100%;
		text-align: center;
		margin-top: rem-calc(20px);

		.more {
			font-family: $font-family-sans-serif;
			font-size: rem-calc(24px);
			font-weight: 300;
			color: $white;
			letter-spacing: rem-calc(2px);
			border-bottom: solid 3px double;

			&:hover {
				@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
			}
		}
	}
}

.flexible-text-module {

	.small-header {
		font-family: $font-family-sans-serif-display;
		font-size: rem-calc(36px);
		font-weight: 700;
		color: $black;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: rem-calc(2px);
		background: $white;
		padding: rem-calc(5px 40px);
		margin: 0;
		display: inline-block;

		@include media-breakpoint-down(sm) {
			font-size: rem-calc(24px);
			padding: rem-calc(0 24px);
		}
	}

	.large-header {
		font-family: $font-family-sans-serif-display;
		font-size: rem-calc(130px);
		font-weight: 300;
		color: $white;
		text-align: center;
		margin-top: rem-calc(-20px);

		@include media-breakpoint-down(sm) {
			font-size: rem-calc(58px);
			margin-top: rem-calc(6px);
		}
	}

    h3.title {
        font-family: $font-family-sans-serif-display;
        font-size: $display2-size;
        font-weight: 300;
        letter-spacing: rem-calc(-2px);
        line-height: rem-calc(63px);
        margin-bottom: rem-calc(30px);
        opacity: 0.9;
    }

    &.auto-2-col-header-span h3 {
        column-span: all;
    }

    p {
        opacity: 0.9;
        font-family: $font-family-body;
        font-size: rem-calc(16px);
        font-weight: 300;
        line-height: rem-calc(30px);
    }

    &.auto-1-col {
        .content {
            text-align: center;

            .content-column {
                text-align: left;
            }
        }
    }

    &.auto-2-col, &.auto-2-col-header-span {
        .content {
            column-count: 2;
            column-gap: 30px;
            text-align: left;

            @include media-breakpoint-down(sm) {
                column-count: 1;
            }
        }
    }

    &.man-2-col-header-span, &.man-2-col {
        .row {
            justify-content: center;
        }
    }
}