// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

//
// Color system
//

$white:  #fff !default;
$gray-100: #efefef;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #5E6977;
$gray-700: #495057 !default;
$gray-800: #4A4A4A;
$gray-900: #212529 !default;
$black:  #000000;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

$blue:    #007396;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #852765;
$red:     #dc3545 !default;
$orange:  #F76B1C;
$yellow:  #FAD961;
$green:   #7DC855;
$teal:    #50E3C2;
$cyan:    #6acbe0;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
) !default;

$theme-colors: (
  primary: $white,
  secondary: $gray-100,
  white: $white,
  teal: $teal,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;


$body-bg: $black;
$body-color: $white;


// Home Hero Overlays
$gradient-blue_purple-map: (
0%: #852765,
58%: #612584,
100%: #3023AE,
);

$gradient-yellow_orange-map: (
0%: #FAD961,
100%: #F76B1C,
);

$gradient-lpurple_dpurple-map: (
0%: #C17AFC,
100%: #711B51,
);

$gradient-black_gray-map: (
0%: #717171,
97%: #000000,
100%: #000000,
);

$gradient-teal_turquoise-map: (
0%: #045871,
7%: #045871,
56%: #037FA5,
100%: #30D2BE,
);

$gradient-dgreen_lgreen-map: (
0%: #D0FFAE,
100%: #50E3C2,
);

$gradient-dgray_lgray-map1: (
0%: #BDBBBE,
100%: #9D9EA3,
);

$gradient-dgray_lgray-map2: (
0%: rgba(255,255,255,0.25),
1%: rgba(254,254,254,0.25),
100%: rgba(0,0,0,0.25),
);

$gradient-dgray_lgray-map3: (
0%: rgba(255,255,255,0.30),
100%: rgba(0,0,0,0.30),
);

$gradient-dblue_lblue-map: (
0%: #6ACBE0,
100%: #037FA5,
);

$gradient-teal_purple-map: (
0%: #473B7B,
51%: #3584A7,
100%: #30D2BE,
);

// Button Radius
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

// Fonts
$font-family-sans-serif-display: "CenturyGothic", Helvetica, Arial, sans-serif;
$font-family-sans-serif: "Rajdhani", Helvetica, Arial, sans-serif;
$font-family-body: "Thasadith", Helvetica, Arial, sans-serif;
$font-family-accent: "LithosPro-Regular", Helvetica, Arial, sans-serif;
$font-family-serif:      'Rosemary', Georgia, "Times New Roman", Times, serif;