.artists, .single-exhibitions {

	.entry-title {
		color: $black;
		background: $white;

		span {
			display: block;
			color: $white;
			background: $black;
			font-weight: 100;
			font-size: 60%;
			text-transform: uppercase;
			letter-spacing: 1px;
		}

		small {
		    font-size: 60%;
		    font-weight: 100;
		    text-transform: uppercase;
		    letter-spacing: 1px;
		    opacity: 0.6;
		}
	}
	.entry-content {
		padding-top: rem-calc(60px);

		@include media-breakpoint-down(sm) {
			padding: 40px 30px 0 30px;
		}

		.external-links {
			font-size: rem-calc(40px);

			.external-link {
				&:hover {
					@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
				}
			}
		}
	}

	.artist-gallery {
		padding: rem-calc(100px) 0;

		.tilter__figure {
			height: auto;
		}
	}

	.artist-gallery-title {
		font-size: 90px;
		hyphens: auto;
	    // overflow-wrap: break-word;
	    // word-wrap: break-word;
		line-height: 1;
		padding: 0 rem-calc(5px);

		a {
			&:hover {
				@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
			}
		}
	}

	.tilter {
		margin-bottom: rem-calc(30px);
	}

	.gallery-image {
		position: relative;
		padding: rem-calc(15px);
		background: $white;

		.artwork-title {
			display: none;
			position: absolute;
			bottom: -2.5rem;
			left: 0;
			// transform: rotate(-90deg);
			// transform-origin: left;
			font-size: rem-calc(11px);
			font-weight: 100;
			color: $white;
			text-transform: uppercase;
			// overflow: hidden;
		    // text-overflow: ellipsis;
		    // width: 320px;
		    padding-left: 1.875rem;
			
			// &:before {
			// 	content: '';
			// 	height: 1px;
			// 	background: $black;
			// 	width: 20px;
			// 	position: absolute;
			// 	left: 0;
			// 	top: 6px;
			// }
		}

		img {
		}
	}
}

.post-type-archive-artists {

	.page-intro {
		margin-bottom: rem-calc(60px);
	}

	.section-header {
		text-transform: uppercase;
		letter-spacing: rem-calc(2px);
	}

}

.tilter__image {
	&.img-holder {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}