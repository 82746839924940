.blog {

	.post {

		.featured-image {
			.cats {
				position: absolute;
				top: -5px;
				left: -5px;
				padding: rem-calc(10px);
				background: $white;
				color: $black;
				font-size: rem-calc(20px);
				text-transform: uppercase;
				letter-spacing: 1px;
				box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.25);
				z-index: 100;

				a {
					color: $black;
				}
			}
		}

		.post-content {
			.entry-title {
				a {
					&:hover {
						@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
					}
				}
			}

			.entry-summary {
				font-weight: 300;
			}

			.event-time {
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 1px;
			}

			.more {
				font-family: $font-family-sans-serif;
				font-weight: 300;
				color: $white;
				letter-spacing: rem-calc(2px);
				text-transform: uppercase;
				border-bottom: solid 3px double;

				&:hover {
					@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
				}
			}
		}
	}

	.post.sticky {
		@include make-row();
		justify-content: space-between;
		margin-bottom: rem-calc(100px);

		.img-container {
			@include make-col-ready();
			position: relative;
			display: block;
			@include aspect-ratio(4, 3);
			width: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			padding: rem-calc(15px);
			padding-left: 0;

			@include media-breakpoint-up('sm') {
				@include make-col(12);
			}

			&:after {
				content: '';
				position: absolute;
				top: -5%;
				left: -5%;
				border: solid 1px $white;
				z-index: 10;
				width: 105%;
				height: 105%;
				transform: rotate(-3deg);
				transition: transform 0.25s linear;
			}

			&:hover {
				&:after {
					transform: rotate(0deg);
				}
			}
		}
		
		.featured-image {
			@include make-col-ready();

			@include media-breakpoint-up('sm') {
				@include make-col(12);
			}

			@include media-breakpoint-up('md') {
				@include make-col(8);
			}
		}

		.post-content {
			@include make-col-ready();

			@include media-breakpoint-up('sm') {
				@include make-col(12);
			}

			@include media-breakpoint-up('md') {
				@include make-col(4);
			}

			.entry-title {
				font-size: rem-calc(45px);
				line-height: 1.2;
				margin-bottom: rem-calc(40px);
			}

			.entry-summary {
				margin-bottom: rem-calc(40px);
			}

			.event-time {
				font-size: rem-calc(26px);
			}

			.more {
				font-size: rem-calc(24px);
			}

			@include media-breakpoint-down(sm) {
				.event-time, .entry-summary, .more {
					padding-left: 45px;
					padding-right: 45px;
				}
			}
		}
	}

	.post:not(.sticky) {
		@include make-col-ready();
		margin-bottom: rem-calc(40px);


		@include media-breakpoint-up(sm) {
			@include make-col(12);
		}

		@include media-breakpoint-up(md) {
			@include make-col(4);
		}

		@include media-breakpoint-down(sm) {
			padding-left: 30px;
			padding-right: 30px;
		}

		.featured-image {
			.cats {
				top: -10px;
				padding: rem-calc(6px);
				font-size: rem-calc(14px);
			}
		}

		.img-container {
			@include make-col-ready();
			position: relative;
			display: block;
			@include aspect-ratio(5, 3);
			width: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			padding: rem-calc(15px);
			padding-left: 0;

			@include media-breakpoint-up('sm') {
				@include make-col(11);
			}

			&:after {
				content: '';
				position: absolute;
				top: -5%;
				left: -5%;
				border: solid 1px $white;
				z-index: 10;
				width: 105%;
				height: 105%;
				transform: rotate(-3deg);
				transition: transform 0.25s linear;
			}

			&:hover {
				&:after {
					transform: rotate(0deg);
				}
			}
		}

		&:nth-child(even) .img-container {
			&:after {
				transform: rotate(3deg);
			}

			&:hover {
				&:after {
					transform: rotate(0deg);
				}
			}
		}

		.post-content {

			.event-time {
				font-size: rem-calc(22px);
			}

			.more {
				font-size: rem-calc(16px);
			}
		}
	}
}

.tilt {
	position: absolute;
}

.hero {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(-180deg, rgba(0,0,0,0.00) 50%, #000000 100%);
		z-index: 100;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 90%, #000000 100%);
		z-index: 103;
	}

	header {
		position: absolute;
		width: 100%;
		padding: 0 10%;
		z-index: 103;
	}
}

.hero__imgwrap {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.hero__imgwrap::after {
	content: '';
	position: absolute;
	background: rgba(76,0,1,0.1);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.hero__img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: auto;
	height: 100vh;
	-webkit-transform: translate3d(-50%,-50%,0);
	transform: translate3d(-50%,-50%,0);
}

.hero__imgwrap .tilt__back,
.hero__imgwrap .tilt__front {
	background-size: auto calc(100vh - 80px);
}

.tilt {
	top: -7px;
	z-index: 101;
}

.single-post {

	.main {
		position: relative;
		top: calc(100vh - 160px);
		z-index: 101;
	}

	.entry-title {
		font-family: $font-family-sans-serif-display;
		font-size: rem-calc(90px);
		margin-top: 45vh;
		text-align: center;
		opacity: 0.95;
		text-shadow: 0 0 8px rgba(0,0,0,0.75);

		@include media-breakpoint-down(sm) {
			font-size: rem-calc(44px);
		}
	}

	.time {
		font-family: $font-family-sans-serif;
		font-weight: 300;
		text-align: center;
		text-transform: uppercase;
		text-shadow: 0 0 8px rgba(0,0,0,0.75);

		@include media-breakpoint-down(sm) {
			font-size: rem-calc(32px);
		}
	}

	.entry-content {
		@include make-col-ready();
		// position: relative;
		// top: calc(100vh - 160px);
		padding: rem-calc(60px 150px 60px 150px);
		margin: 0 auto 100px auto;
		font-family: $font-family-body;
		font-size: rem-calc(18px);
		font-weight: 300;
		border: 2px solid transparent;
		border-image: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
		border-image-slice: 1;

		@include media-breakpoint-down(sm) {
			@include make-col(12);
			padding-left: 30px;
			padding-right: 30px;
			max-width: calc(100% + 30px);
			width: calc(100% + 30px);
			margin-left: -15px;
			margin-bottom: 40px;
		}

		@include media-breakpoint-up(md) {
			@include make-col(10);
		}
	}

	.ticket-link {
		font-size: rem-calc(20px);
		font-weight: 700;
		text-align: center;

		&:hover {
			@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
		}
	}

	@keyframes flickerAnimation {
	  0%   { opacity: 1; }
	  50%  { opacity: 0.25; }
	  100% { opacity: 1; }
	}

	.scroll-down {
		position: absolute;
		bottom: 40px;
		left: calc(50% - 30px);
		z-index: 980;
		animation: flickerAnimation 4s infinite;

		&:hover {
			cursor: pointer;
		}

		svg {
			width: 60px;
		}
	}
}