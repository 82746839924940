body:not(.home) {
	div.container[role="document"] {
		padding: rem-calc(32px);
		margin-top: rem-calc(60px);
		transition: all 33ms;
	}
}



.page-header {
	margin-bottom: rem-calc(30px);

	h1 {
		font-weight: 100;
	    text-transform: uppercase;
	    letter-spacing: 10px;
	}
}
	

.page-intro {
	padding-top: rem-calc(30px);
	font-size: rem-calc(20px);
	font-weight: 100;

	p:first-child:first-letter {
		float: left;
		font-family: $font-family-serif;
		font-size: rem-calc(60px);
		line-height: 0.75;
		padding-top: 8px;
		padding-right: 8px;
		padding-left: 3px;
	}
}

/***** Contact Page *****/
.contact-form {
	margin-bottom: rem-calc(60px);
}

/***** Contact Form 7 *****/
#wpcf7-f7-p121-o1 {

	.row {
		margin-bottom: 14px;
	}

	.wpcf7-form-control-wrap {
		width: 100%;
		background: $white;
	}

	input[type=text], input[type=email], input[type=tel], textarea, select {
		border: solid 3px $white;
		font-size: 18px;
		font-weight: 400;
		width: 100%;
		background: $white;
		color: $black;
		font-family: $font-family-sans-serif;
	}

	::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	    font-family: $font-family-sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: $black;
		opacity: 1;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	   font-family: $font-family-sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: $black;
		opacity: 1;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
	   font-family: $font-family-sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: $black;
		opacity: 1;
	}
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
	   font-family: $font-family-sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: $black;
		opacity: 1;
	}
	::-ms-input-placeholder { /* Microsoft Edge */
	   font-family: $font-family-sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: $black;
		opacity: 1;
	}

	::placeholder { /* Most modern browsers support this now. */
	   font-family: $font-family-sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: $black;
		opacity: 1;
	}

	&::placeholder {
		font-family: $font-family-sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: $black;
		opacity: 1;
	}

	select {
		background: transparent url('../images/select-arrow.svg') no-repeat right 10px center;
		background-size: 4%;
		padding-right: 60px;
		-webkit-appearance: none;

		option:first-child {
			line-height: 20px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.name-num {
		.wpcf7-form-control-wrap {
			width: 49%;
		
			&:first-child {
				margin-right: 2%;
			}
		}
	}

	input[type=submit] {
		@extend .btn;
		@extend .btn-lg;
		@extend .btn-light;
		text-transform: uppercase;

		&:hover {
			background: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
		    -webkit-background-clip: text;
		    -webkit-text-fill-color: transparent;
		}
	}
}

.map-parent {
	height: 400px;
	
	#map {
		width: 100%;
		height: 100%;
	}
}
	


	