@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Gradient Text
@mixin gradient-text($gradient, $bg : 'light') {
  @supports(mix-blend-mode: lighten) {
    display: inline-block;
    position: relative;
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      
      background: unquote($gradient);
      pointer-events: none;
    }

    @if ($bg == 'light') {
      color: #000;
      background: #fff;
      mix-blend-mode: multiply;

      &::before {
        mix-blend-mode: screen;
      }
    } @else {
      color: #fff;
      background: #000;
      mix-blend-mode: lighten;

      &::before {
        mix-blend-mode: multiply;
      }
    }
  }
}

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback), 
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;
  
  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }
  
  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;
    
    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }
  
  @if $fallback {
    font-size: $fallback;
  }
  
  font-size: $responsive;
}

// @include gradient(gradient--concat($gradient--concat));
@mixin background-gradient($gradient-degrees, $gradient--concat) {
    $ie_variables: gradient--ie($gradient--concat);
    $start-color: map-get($ie_variables, 'start');
    $finish-color: map-get($ie_variables, 'finish');

  background-color: mix($start-color, $finish-color); /* Fallback */
  background-image: linear-gradient($gradient-degrees, gradient--concat($gradient--concat)); 
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#{ie-hex-str($start-color)}', EndColorStr='#{ie-hex-str($finish-color)}')";
}

@mixin border-gradient($gradient-degrees, $gradient--concat, $width) {
    $ie_variables: gradient--ie($gradient--concat);
    $start-color: map-get($ie_variables, 'start');
    $finish-color: map-get($ie_variables, 'finish');

    // background: linear-gradient($gradient-degrees, gradient--concat($gradient--concat));
    background: $white;
    border-radius: $border-radius-lg;
    position: relative;
    text-align: center;
    box-sizing: border-box;

    &::after {
      content: attr(data-content);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: $btn-padding-y-lg $btn-padding-x-lg;
      border-radius: $btn-border-radius-lg;
      background: $white;
      text-align: center;
      border-radius: $border-radius-lg;
      z-index: 6;
      @extend .animated;
    }

    &::before {
	    border-radius: $btn-border-radius-lg;
	    content: '';
	    background-image: linear-gradient($gradient-degrees, gradient--concat($gradient--concat));
	    width: calc(100% + (#{$width} * 2));
	    height: calc(100% + (#{$width} * 2));
	    padding: 0;
	    padding: $btn-padding-y-lg $btn-padding-x-lg;
	    top: -$width;
	    left: -$width;
	    position: absolute;
	    z-index: 5;
	}

  &:hover:after {
    background: transparent;
    color: $white;
  }
}

// !!!!! Need to test fallback support for this !!!!!
@mixin text-gradient($gradient-degrees, $gradient--concat) {
  background: -moz-linear-gradient($gradient-degrees, gradient--concat($gradient--concat));
  background: -webkit-linear-gradient($gradient-degrees, gradient--concat($gradient--concat));
  background: linear-gradient($gradient-degrees, gradient--concat($gradient--concat));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


// Concat :: Gradient Mixin
// Concatinate the gradient map properties and values into structured CSS
@function gradient--concat($gradient--concat){
    $return-new: '';

    @each $position, $color in $gradient--concat {
        $return: $color + ' ' + $position + gradient--position($position);
        $return-new: $return-new + '' + $return;
    }

    @return unquote($return-new);
}

// Position :: Gradient Mixin
// Add commas to the end of every property concationation, except the last one. 
@function gradient--position($position){
    @if ( $position != 100%) {
        @return ",";
    }
    @else {
        @return "";
    }
}

// Internet Explore Fallback
// Extract the first and last color variables for "-ms-filter"
@function gradient--ie($gradient--concat){
    $start_map: '';
    $end_map: '';

    @each $position, $color in $gradient--concat {
        @if ( $position == 0%) {
            $start_map: (
                'start': $color
                )
        }
        @if ( $position == 100%) {
            $end_map: (
                'finish': $color
                )
        }   
    }
    @return map-merge($start_map, $end_map);
}

/* Gradient Mixins */
//
// Gradient Pallette
@mixin blue_purple-bg() {
@include background-gradient(-135deg, $gradient-blue_purple-map);
}

@mixin blue_purple-text() {
@include text-gradient(-135deg, $gradient-blue_purple-map);
}

@mixin blue_purple-border() {
@include border-gradient(-135deg, $gradient-blue_purple-map, 2px);
}

@mixin yellow_orange-bg() {
@include background-gradient(0deg, $gradient-yellow_orange-map);
}

@mixin yellow_orange-text() {
@include text-gradient(0deg, $gradient-yellow_orange-map);
}

@mixin yellow_orange-border() {
@include border-gradient(0deg, $gradient-yellow_orange-map, 2px);
}

@mixin lpurple_dpurple-bg() {
@include background-gradient(-225deg, $gradient-lpurple_dpurple-map);
}

@mixin lpurple_dpurple-text() {
@include text-gradient(-225deg, $gradient-lpurple_dpurple-map);
}

@mixin lpurple_dpurple-border() {
@include border-gradient(-225deg, $gradient-lpurple_dpurple-map, 2px);
}

@mixin black_gray-bg() {
@include background-gradient(-180deg, $gradient-black_gray-map);
}

@mixin black_gray-text() {
@include text-gradient(-180deg, $gradient-black_gray-map);
}

@mixin black_gray-border() {
@include border-gradient(-180deg, $gradient-black_gray-map, 2px);
}

@mixin teal_turquoise-bg() {
@include background-gradient(224deg, $gradient-teal_turquoise-map);
}

@mixin teal_turquoise-text() {
@include text-gradient(224deg, $gradient-teal_turquoise-map);
}

@mixin teal_turquoise-border() {
@include border-gradient(224deg, $gradient-teal_turquoise-map, 2px);
}

@mixin dgreen_lgreen-bg() {
@include background-gradient(-225deg, $gradient-dgreen_lgreen-map);
}

@mixin dgreen_lgreen-text() {
@include text-gradient(-225deg, $gradient-dgreen_lgreen-map);
}

@mixin dgreen_lgreen-border() {
@include border-gradient(-225deg, $gradient-dgreen_lgreen-map, 2px);
}

@mixin dgray_lgray-bg() {
@include background-gradient(-226deg, $gradient-dgray_lgray-map1);
@include background-gradient(-226deg, $gradient-dgray_lgray-map2);
@include background-gradient(-226deg, $gradient-dgray_lgray-map3);
}

@mixin dblue_lblue-bg() {
@include background-gradient(-226deg, $gradient-dblue_lblue-map);
}

@mixin dblue_lblue-text() {
@include border-gradient(-226deg, $gradient-dblue_lblue-map);
}

@mixin dblue_lblue-border() {
@include border-gradient(-226deg, $gradient-dblue_lblue-map, 2px);
}

@mixin teal_purple-bg() {
@include background-gradient(-225deg, $gradient-teal_purple-map);
}

@mixin teal_purple-text() {
@include text-gradient(-225deg, $gradient-teal_purple-map);
}

@mixin teal_purple-border() {
@include border-gradient(-225deg, $gradient-teal_purple-map, 2px);
}