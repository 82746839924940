header.banner {
	position: fixed;
	top: 32px;
	left: 32px;
	width: calc(100% - 64px);
	border-top: solid 1px $white;
	z-index: 1000;

	&:before {
		content: '';
		position: fixed;
		top: 32px;
		left: 32px;
		width: 1px;
		height: calc(100% - 64px);
		background: $white;
	}

	&:after {
		content: '';
		position: fixed;
		top: 32px;
		right: 32px;
		width: 1px;
		height: calc(100% - 64px);
		background: $white;
	}

	.container {
		max-width: none;
		padding: 0;
	}
}

.wrapper-menu {
	position: fixed;
	top: 40px;
	left: 40px;
	width: 50px;
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	transition: transform 330ms ease-out;

	@include media-breakpoint-down(sm) {
		top: 10px;
		left: 10px;
		width: 20px;
		height: 20px;
	}


	&.open {
		transform: rotate(-45deg);
		z-index: 10001;
	}

	.line-menu {
		background-color: #fff;
		width: 100%;
		height: 2px;
	}
}

&.menu {
	position: fixed;
	top: 3px;
	left: 110px;
	font-family: $font-family-sans-serif;
	font-weight: 100;
	font-size: rem-calc(72px);
	color: $white;

	@include media-breakpoint-down(sm) {
		font-size: rem-calc(20px);
		top: 6px;
		left: 40px;
	}
}

.menu-icon {
	&:hover {
		cursor: pointer;
		.menu {
			background: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
		    -webkit-background-clip: text;
		    -webkit-text-fill-color: transparent;
			background-size: 300% 300%;

			animation: AnimatedGradient 1s ease infinite;

			@-webkit-keyframes AnimatedGradient {
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			@-moz-keyframes AnimatedGradient {
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			@keyframes AnimatedGradient { 
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			transition: all .5s;
		}

		.wrapper-menu .line-menu {
			background: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
			background-size: 300% 300%;

			animation: AnimatedGradient 1s ease infinite;

			@-webkit-keyframes AnimatedGradient {
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			@-moz-keyframes AnimatedGradient {
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
			@keyframes AnimatedGradient { 
			    0%{background-position:0% 50%}
			    50%{background-position:100% 50%}
			    100%{background-position:0% 50%}
			}
		}
	}
}

.line-menu.half {
	width: 50%;
}

.line-menu.start {
	transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
	transform-origin: right;
}

.open .line-menu.start {
	transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
	align-self: flex-end;
	transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
	transform-origin: left;
}

.open .line-menu.end {
	transform: rotate(-90deg) translateX(-3px);
}

.nav-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	background: url('../images/menu-bg.svg') no-repeat left top;
	background-size: cover;
	transition: all 0.1s cubic-bezier(0.54, -0.81, 0.57, 0.57);

	.nav-primary {
		font-family: $font-family-sans-serif;
		font-size: rem-calc(24px);
		font-weight: 700;
		color: $black;
		letter-spacing: 2px;
	}
}

.menu-closed {
	.menu-icon {
		&.menu-sm {

			.wrapper-menu {
				width: 30px;
				height: 30px;

				@include media-breakpoint-down(sm) {
					top: 10px;
					left: 10px;
					width: 20px;
					height: 20px;
				}
			}

			.menu {
				top: 36px;
			    left: 90px;
			    font-size: rem-calc(30px);

			    @include media-breakpoint-down(sm) {
					font-size: rem-calc(20px);
					top: 6px;
					left: 40px;
				}
			}
		}
	}
	
	.nav-wrapper {
		width: 0;
		height: 0;

		.nav-primary {
			display: none;
		}
	}
}

.menu-open {

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $black;
		opacity: 0.8;
		z-index: 990;
	}

	.menu, .wrapper-menu {
		mix-blend-mode: difference;
		z-index: 10003;
	}

	.menu {
		top: 22px;
	}

	.nav-wrapper {
		width: 100vw;
		height: 100vh;

		.nav-primary {
			display: block;
		    font-size: rem-calc(24px);
		    font-family: $font-family-sans-serif;
		    font-weight: 100;
		    letter-spacing: 16px;
		    mix-blend-mode: difference;
		    opacity: .8;
		    text-transform: uppercase;
		    transition: all 1s;

		    ul {
		    	flex-wrap: nowrap;
		    	flex-direction: column;

		    	@include media-breakpoint-down(sm) {
		    		left: 30px;
		    	}
		    }

			a {
				color: $white;

				&:hover {
					font-weight: 900;
					letter-spacing: 15.5px;
					@include gradient-text('linear-gradient(to right,#ff4423, #ae5aff, #19caf0)', 'dark');
				}
			}
		}
	}
}

.brand {
	position: absolute;
	top: -25px;
	left: calc(50% - 20px);
	display: block;
	width: 40px;
	height: 43px;
	opacity: 1;
	transition: opacity .3s;
	z-index: 10000;

	&.show {
		opacity: 1 !important;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 24px;
		left: calc(50% - 10px);
		width: 10px;
		height: 1px;
		background: $black;
		z-index: -1;
	}
}

.home .brand {
	opacity: 0;
}

// Page Overrides
// .single-artists {
// 	.menu-icon {
// 		display: none;
// 	}

// 	.nav-wrapper {
// 		display: none;
// 	}
// }

// Variables
// $background-tb: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
// $background-rl: linear-gradient(269deg,#ff4423,#ae5aff 59%,#19caf0);
$progress: 3px;

// Progress
.progress-top,
.progress-right,
.progress-bottom,
.progress-left {
	position: fixed;
	z-index: 999;

	background: linear-gradient(-269deg,#ff4423,#ae5aff 59%,#19caf0);
	background-size: 300% 300%;

	animation: AnimatedGradient 10s ease infinite;

	@-webkit-keyframes AnimatedGradient {
	    0%{background-position:0% 50%}
	    50%{background-position:100% 50%}
	    100%{background-position:0% 50%}
	}
	@-moz-keyframes AnimatedGradient {
	    0%{background-position:0% 50%}
	    50%{background-position:100% 50%}
	    100%{background-position:0% 50%}
	}
	@keyframes AnimatedGradient { 
	    0%{background-position:0% 50%}
	    50%{background-position:100% 50%}
	    100%{background-position:0% 50%}
	}
}

.progress-top,
.progress-bottom {
  height: $progress;
  // background: $background-tb;
}

.progress-right,
.progress-left {
  width: $progress;
  // background: $background-rl;
}

.progress-top,
.progress-right {
  top: 0;
}

.progress-top,
.progress-left {
  left: 0;
}

.progress-bottom,
.progress-left {
  bottom: 0;
}

.progress-bottom,
.progress-right {
  right: 0;
}